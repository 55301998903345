@font-face {
  font-family: "Pretendard Variable";
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url("./PretendardVariable.woff2") format("woff2-variations");
}

@font-face {
  font-family: "SCoreDream";
  font-weight: 100;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamThin.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamThin.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamThin.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamThin.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamThin.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 200;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraLight.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 300;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamLight.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamLight.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamLight.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamLight.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamLight.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 400;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamRegular.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamRegular.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamRegular.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamRegular.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamRegular.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 500;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamMedium.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamMedium.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamMedium.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamMedium.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamMedium.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 600;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBold.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 700;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraBold.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraBold.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraBold.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamExtraBold.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 800;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamHeavy.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamHeavy.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamHeavy.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamHeavy.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamHeavy.ttf")
      format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "SCoreDream";
  font-weight: 900;
  font-style: normal;
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBlack.eot");
  src: url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBlack.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBlack.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBlack.woff")
      format("woff"),
    url("https://cdn.jsdelivr.net/gh/webfontworld/score/SCoreDreamBlack.ttf")
      format("truetype");
  font-display: swap;
}
