/* App.css */
.App {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* body {
  background: url('/app/src/assets/rui_gif.gif') no-repeat center center fixed;
  background-size: cover;
} */

body {
  scroll-behavior: smooth;
}

.pages {
  height: 100vh; /* Adjust as needed */
  overflow: hidden;
}

.sidebar {
  width: 200px;
  background-color: #333;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

.sidebar a {
  text-decoration: none;
  color: white;
}

.content {
  flex: 1;
  padding: 20px;
}

/* Style for collapsed menu */
.content.collapsed {
  margin-left: 60px; /* Adjust as needed */
}

.required-field::before {
  content: "*";
  color: red;
}

.modal {
  /* Styles to create a modal overlay */
  position: fixed;
  /* top: 8.3333vh; */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.image-zoomed {
  max-width: 90vw; /* Set your desired larger width */
  max-height: 90vh; /* Set your desired larger height */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
}

@media (max-width: 480px) {
  .modal-content {
    /* Styles for the modal container */
    background-color: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    /* margin-bottom: 8.3333vh; */
    border-radius: 30px;
    overflow-y: auto;
  }

  .modal-image {
    /* Styles for the image */
    max-width: 100vw;
    max-height: 80vh;
    padding-top: 2vh;
  }

  .modal-explanation {
    /* Styles for the explanations or additional content */
    margin-bottom: 0px;
  }

  .body-scroll-lock {
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: 0vh;
    left: 82vw;
    cursor: pointer;
  }

  .image-set-container {
    max-height: 27.7778vh; /* Adjust the height as needed */
    margin-top: 1.5625vw;
  }

  .modal-subImage {
    max-width: 90vw;
    padding-top: 20px;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .modal-content {
    /* Styles for the modal container */
    background-color: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    /* margin-bottom: 8.3333vh; */
    border-radius: 30px;
    overflow-y: auto;
  }

  .modal-image {
    /* Styles for the image */
    max-width: 100vw;
    max-height: 80vh;
    padding-top: 8vh;
  }

  .modal-explanation {
    /* Styles for the explanations or additional content */
    margin-bottom: 0px;
  }

  .body-scroll-lock {
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: 2vh;
    left: 90vw;
    cursor: pointer;
  }

  .image-set-container {
    max-height: 27.7778vh; /* Adjust the height as needed */
    margin-top: 1.5625vw;
    /* overflow-y: auto; */
  }

  .modal-subImage {
    max-width: 90vw;
    padding-top: 20px;
  }
}

@media (min-width: 1025px) {
  .modal-content {
    /* Styles for the modal container */
    background-color: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 50vw;
    height: 100vh;
    /* margin-bottom: 8.3333vh; */
    border-radius: 30px;
    overflow-y: auto;
  }

  .modal-image {
    /* Styles for the image */
    max-width: 100vw;
    max-height: 80vh;
    padding-top: 8vh;
  }

  .modal-explanation {
    /* Styles for the explanations or additional content */
    margin-bottom: 0px;
  }

  .body-scroll-lock {
    overflow: hidden;
  }

  .close-button {
    position: absolute;
    top: 1vh;
    left: 71vw;
    cursor: pointer;
  }

  .image-set-container {
    max-height: 27.7778vh; /* Adjust the height as needed */
    margin-top: 1.5625vw;
    /* overflow-y: auto; */
  }

  .modal-subImage {
    max-width: 90vw;
    /* max-height: 20vh; */
    padding-top: 20px;
  }
  .image-hover {
    transition: all 0.2s linear;
  }
  .image-hover:hover {
    transform: scale(1.2);
  }
}
